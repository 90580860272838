<template>
  <div>
    <div class="fill-height purple-gradient">
      <Navbar />
      <UserNavbar />
      <v-container class="px-5 px-sm-9">
        <SearchBar />
      </v-container>
    </div>
    <div
      :style="[
        $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
          ? { 'padding-bottom': '30px' }
          : { 'padding-bottom': '70px' },
      ]"
      class="body-container"
    >
      <v-container class="px-10 px-md-auto pb-md-0 mb-16">
        <v-row>
          <v-col cols="12">
            <div class="text-h2 text-md-h1 font-weight-bold mx-0">Ayuda</div>
          </v-col>
          <!-- <v-row> -->
          <!-- <v-col cols="12" xs="0" sm="0" md="0" lg="6" xl="6"></v-col> -->
          <!-- <v-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
            <SimpleSearchBar class="my-10" />
            </v-col> -->
          <!-- </v-row> -->

          <v-col cols="12">
            <v-tabs color="secondary" show-arrows slider-size="5">
              <v-tabs-slider></v-tabs-slider>
              <v-tab
                class="btn-background-transparent text-capitalize black--text text-h3 px-3 px-sm-6"
                :ripple="false"
                :active-class="'font-weight-bold'"
                @click="tabSelector('client')"
              >
                Clientes
              </v-tab>
              <v-tab
                class="btn-background-transparent text-capitalize black--text text-h3 px-3 px-sm-6"
                :ripple="false"
                :active-class="'font-weight-bold'"
                @click="tabSelector('provider')"
              >
                Proveedores
              </v-tab>
            </v-tabs>

            <v-divider></v-divider>
          </v-col>

          <v-col cols="12">
            <div class="mx-0 mt-5 text-h2 text-md-h1 font-weight-bold">
              Preguntas Frecuentes
            </div>
          </v-col>
        </v-row>

        <div v-show="tabSelection === 'client'" class="px-md-auto">
          <v-expansion-panels class="mb-6 mt-6" flat accordion multiple>
            <v-expansion-panel
              v-for="(faq, id) in filteredClientFAQs"
              :key="id"
            >
              <v-expansion-panel-header
                expand-icon="fas fa-angle-down"
                class="pl-0"
              >
                <span class="text-h4 font-weight-bold pr-5">
                  {{ faq.question }}
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="text-pre-line text-body-1 my-6">
                {{ faq.answer }}
              </v-expansion-panel-content>
              <v-divider class="my-5 secondary"></v-divider>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>

        <div v-show="tabSelection === 'provider'" class="px-md-auto">
          <v-expansion-panels class="mb-6 mt-6" flat accordion multiple>
            <v-expansion-panel
              v-for="(faq, id) in filteredProviderFAQs"
              :key="id"
            >
              <v-expansion-panel-header
                expand-icon="fas fa-angle-down"
                class="pl-0"
              >
                <span class="text-h4 font-weight-bold pr-5">
                  {{ faq.question }}
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="text-pre-line text-body-1 my-6">
                {{ faq.answer }}
              </v-expansion-panel-content>
              <v-divider class="my-5 secondary"></v-divider>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-container>
    </div>
    <Footer />
  </div>
</template>
<script>
import Navbar from "@/components/User/Shared/Navbar.vue";
import UserNavbar from "@/components/User/Shared/UserNavbar.vue";
import SearchBar from "@/components/Shared/SearchBar.vue";
import Footer from "@/components/Shared/Footer.vue";
// import SimpleSearchBar from "@/components/Shared/SimpleSearchBar.vue";
import { GET_QUESTIONS } from "@/graphql/queries";
import { useQuery } from "@/graphql/index";

export default {
  name: "Help",
  components: {
    Navbar,
    UserNavbar,
    SearchBar,
    Footer,
    // SimpleSearchBar,
  },
  data: () => ({
    tabSelection: "client",
    frequentlyAskedQuestions: [],
  }),
  computed: {
    filteredClientFAQs() {
      return this.frequentlyAskedQuestions.filter(
        (faq) => faq.type == "client"
      );
    },
    filteredProviderFAQs() {
      return this.frequentlyAskedQuestions.filter(
        (faq) => faq.type == "provider"
      );
    },
  },
  async created() {
    await this.fetchZaturnaQuestion();
  },
  methods: {
    async fetchZaturnaQuestion() {
      const { data, errors } = await useQuery(GET_QUESTIONS, {});
      if (data) {
        this.frequentlyAskedQuestions = data.zaturnaQuestions;
      } else if (errors) {
        console.log(errors, 2);
      }
    },
    tabSelector(type) {
      return (this.tabSelection = type);
    },
  },
};
</script>
<style scoped lang="scss">
.purple-gradient {
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    rgba(98, 37, 130, 1) 100%
  );
}
.simple-search-bar {
  width: 50%;
  margin-left: auto;
  margin-right: 0;
}
.btn-background-transparent::before {
  background-color: transparent !important;
}
</style>
